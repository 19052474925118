.root {

    position: relative;

}

.header {

    display: flex;
    justify-content: center;
    font-family: 'eva';
    font-weight: 500;
    text-transform: uppercase;
    position: fixed;
    top: 0px;
    width: 100%;
    letter-spacing: var(--small-inter);
    padding-top: 3px;

    @include small {

        flex-direction: column;
        align-items: center;

    }
    
    a {

        margin: 0px 10px;

    }

    .button {

        display: none;

        &.selected {

            display: block;

        }

    }


}