/* ------------------------------------------------------------------ */
/*        HEADING DECLARATION
/* ------------------------------------------------------------------ */


html, body {

    font-family: 'synd', sans-serif;
    font-feature-settings: normal;
    font-variant-ligatures: historical-ligatures discretionary-ligatures common-ligatures no-contextual;
    font-size: var(--courant_font);
    line-height: 1.3;
    letter-spacing: var(--courant-inter);
    background-color: var(--blanc);
    color: var(--noir);


    transition: color $time $ease,
                background-color $time $ease;

}
a {

    cursor: pointer;
    color: var(--noir);
    text-decoration: none;
    transition: color $time $ease;

    &:hover {

        color: rgb(149, 149, 149) !important;

    }

}

.intro_done .intro {

    display: none;

}

.snap {

    scroll-snap-type: y mandatory;

}

.not_mob {

    @include mobile {

        display: none!important;

    }

}

.mob {

    display: none;

    @include mobile {

        display: block;

    }

}

.strong {

    font-family: 'eva';
    font-weight: 500;
}
