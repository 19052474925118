.home {

    margin-bottom: 320px;

    @include mobile {

        margin-bottom: 100px;

    }

    .figure {

        margin-top: 320px;

        @include mobile {

            margin-top: 100px;

        }

        .image_wrapper {

            width: 100vw;
            height: calc((100vw * var(--th)) / var(--tw));
    
            img {
    
                opacity: 0;
                transform: translateY(-60px);
                width: 100vw;
            
                transition: transfom 0.3s ease,
                            opacity 0.3s ease;
            
            }
            
            &.inview img {
            
                opacity: 1;
                transform: translateY(0px);
            
            }
    
        }
    
        .text_content {
    
            text-align: center;
            text-transform: uppercase;
            font-family: 'eva';
            font-weight: 500;
            position: relative;
            top: 18px;
    
            strong {
    
                font-size: var(--med-font);
                letter-spacing: var(--med-inter);
                line-height: 0.3;

            }

            @include mobile {

                top: 7px;

            }
    
        }

    }

}