.information {

    .title_group, .intro {

        font-size: var(--med-font);
        letter-spacing: var(--med-inter);
        text-align: center;
        text-transform: uppercase;
        margin-top: 200px;
        font-family: 'eva';
        font-weight: 500;

        @include mobile {

            margin-top: 47px;

        }
    }

    .intro {

        line-height: 1;


        @include mobile {

            margin: 90px 0px;
            margin-top: 120px;

        }

    }

    .line {

        display: flex;

        &_year {

            width: 57px!important;

            @include mobile {

                width: 37px!important;
                font-size: 13px;

            }

        }

        &_info {

            width: calc(100% - 41px);

        }

        p {

            letter-spacing: 1.65px;
            
            @include mobile {

                font-size: 13px;
                letter-spacing: 1.1px;

            }

        }

        strong {

            font-family: 'eva';
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: var(--small-inter);

            @include mobile {

                font-size: 11px;
                position: relative;
                top: 0px;

            }
            

        }

    }

}