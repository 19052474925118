/* ------------------------------------------------------------------ */
/*        FONT FACE DECLARATION
/* ------------------------------------------------------------------ */

// $base-fonts-path : '/wp-content/themes/carrie/assets/fonts';
@font-face {
    font-family: 'eva';
    src: url(https://evarobarts.com/assets/fonts/SyndicatGroteskEva-Bold.woff2),
         url(https://evarobarts.com/assets/fonts/SyndicatGroteskEva-Bold.woff) format('woff'),
         url(https://evarobarts.com/assets/fonts/SyndicatGroteskEva-Bold.ttf) format('truetype'),
         url(https://evarobarts.com/assets/fonts/SyndicatGroteskEva-Bold.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'eva';
    src: url(https://evarobarts.com/assets/fonts/SyndicatGroteskEva-BoldItalic.woff2),
         url(https://evarobarts.com/assets/fonts/SyndicatGroteskEva-BoldItalic.woff) format('woff'),
         url(https://evarobarts.com/assets/fonts/SyndicatGroteskEva-BoldItalic.ttf) format('truetype'),
         url(https://evarobarts.com/assets/fonts/SyndicatGroteskEva-BoldItalic.otf) format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'synd';
    src: url(https://evarobarts.com/assets/fonts/SyndicatGrotesk-Regular.woff2),
         url(https://evarobarts.com/assets/fonts/SyndicatGrotesk-Regular.woff) format('woff'),
         url(https://evarobarts.com/assets/fonts/SyndicatGrotesk-Regular.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'synd';
    src: url(https://evarobarts.com/assets/fonts/SyndicatGrotesk-Regular.woff2),
         url(https://evarobarts.com/assets/fonts/SyndicatGrotesk-Regularc.woff) format('woff'),
         url(https://evarobarts.com/assets/fonts/SyndicatGrotesk-Regular.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}
